<template>
  <div>
    <v-container
      v-if="loaded"
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.smAndDown
      }"
      class="pt-0"
    >
      <DesktopBackButton v-if="$vuetify.breakpoint.mdAndUp" class="mt-2" />

      <div
        v-if="staff && staff.detuserid"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        class="d-flex mt-3"
      >
        <!-- Staff details card -->
        <div class="flex-shrink-1 order-md-2">
          <StaffDetailsCard
            :disable-verify-access="true"
            :location-id="locationId"
            :staff="staff"
            :staff-id="staffId"
          />
        </div>

        <!-- Apps list -->
        <div
          :class="{ 'mt-3': $vuetify.breakpoint.smAndDown }"
          class="flex-md-grow-1 order-md-1 mt-3"
        >
          <v-card class="msa-list">
            <v-row v-if="$vuetify.breakpoint.mdAndUp" xs12>
              <v-col class="pt-0 pb-0">
                <v-text-field
                  id="delegate-apps--search-field"
                  v-model="searchString"
                  aria-label="filter delegated apps list"
                  class="data-table--search-field"
                  hide-details
                  label=""
                  outlined
                  placeholder="Search apps"
                  prepend-inner-icon="search"
                  @input="onSearchChangeDebounced"
                />
              </v-col>
            </v-row>
            <div v-if="!hasAppsFiltered">
              <v-row>
                <v-col>
                  <div class="pl-4 pt-4 pb-2">
                    <p>
                      No apps found.
                    </p>
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-if="hasAppsFiltered">
              <v-row
                class="d-flex align-center data-table--header ml-0 mr-0 mt-3 mb-3"
              >
                <v-col class="pl-0 pr-0">
                  <div class="d-flex align-center pl-3">
                    <div>
                      <span class="ma-3">Apps to delegate</span>
                    </div>
                    <v-spacer />
                    <v-checkbox
                      id="delegate-appps--toggle-all-checkbox"
                      v-model="isAllDelegated"
                      :disabled="
                        hasSearchString ||
                          (!fnActionByName(uIActions.DELEGATE_APP) &&
                            !fnActionByName(uIActions.UNDELEGATE_APP))
                      "
                      aria-label="Toggle all apps"
                      class="pr-4"
                      @click.native.capture="toggleAll($event)"
                    >
                      <span slot="prepend" class="pt-1">All</span>
                    </v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <div>
                <v-list two-line>
                  <div
                    v-for="app in applications"
                    :id="`delegate-apps--row-${app.code}`"
                    :key="app.code"
                  >
                    <v-list-item>
                      <v-list-item-avatar tile>
                        <AppIcon :app-name="app.icon" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-row>
                          <v-col>
                            <span class="listTitle">{{ app.name }}</span>
                          </v-col>
                        </v-row>
                      </v-list-item-content>

                      <v-list-item-action-text>
                        <AsyncCheckbox
                          :id="`delegate-apps--row-${app.code}-checkbox`"
                          :aria-label="app.name"
                          :context="delegatedAppTaskIds"
                          :disabled="
                            !fnActionByName(uIActions.DELEGATE_APP) &&
                              !fnActionByName(uIActions.UNDELEGATE_APP)
                          "
                          :item="app"
                          key-prop="taskId"
                          @click.native.capture="
                            toggleAppDelegation($event, app)
                          "
                        />
                      </v-list-item-action-text>
                    </v-list-item>
                  </div>
                </v-list>
              </div>
            </div>
          </v-card>
        </div>
      </div>
      <Dialog
        ref="netRegIdNotPresent"
        actions="Ok"
        message="If this problem persists please call EDConnect on '1300 32 32 32' and quote the following for reference: 'Short name cannot be found'. You may wish to write this down now, or take a screen capture."
        title="A system error has occurred"
        type="error"
      />
    </v-container>
  </div>
</template>

<script>
import DesktopBackButton from "../components/DesktopBackButton";
import StaffDetailsCard from "../components/StaffDetailsCard";
import { AppIcon } from "@nswdoe/doe-ui-core";

import AsyncCheckbox from "../components/AsyncCheckbox";
import Dialog from "@/components/Dialog";

import { mapGetters } from "vuex";
import searchDebounce from "@/mixins/searchDebounce";
import { UI_ACTIONS } from "@/utils/constants";

export default {
  components: {
    DesktopBackButton,
    StaffDetailsCard,
    AppIcon,
    AsyncCheckbox,
    Dialog
  },
  data() {
    return {
      staffId: this.$route.params.staffId,
      loaded: false,
      defaultBack: null,
      netRegIdNotPresent: false,
      searchString: this.delegateAppFilter
    };
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      locType: "selectedLocType",
      locationName: "selectedSchoolName",
      delegateAppFilter: "delegateAppFilter",
      delegatableApplications: "delegatableApplications",
      unfilteredDelegatableApplications: "unfilteredDelegatableApplications",
      fnActionByName: "getActionByName"
    }),
    staff: function() {
      return (
        this.$store.getters.delegate(this.locationId, this.staffId) ||
        this.$store.getters.staff(this.locationId, this.staffId)
      );
    },
    fullName: function() {
      if (this.staff) {
        return this.staff.firstname + " " + this.staff.lastname;
      }
      return "";
    },
    applications: function() {
      return this.delegatableApplications(this.locationId, this.staffId);
    },
    unfilteredApplications: function() {
      return this.unfilteredDelegatableApplications(
        this.locationId,
        this.staffId
      );
    },
    hasAppsFiltered: function() {
      return this.applications && this.applications.length;
    },
    delegatedAppTaskIds: function() {
      return this.unfilteredApplications
        ? this.unfilteredApplications
            .filter(a => a.provisioned)
            .map(a => a.taskId)
        : [];
    },
    isAllDelegated: {
      get() {
        return this.unfilteredApplications.every(a => a.provisioned);
      },
      set(newValue) {
        return newValue;
      }
    },
    hasSearchString: function() {
      return !!this.searchString;
    },
    uIActions: function() {
      return UI_ACTIONS;
    }
  },
  created: async function() {
    this.$store.commit("setDelegatableAppsFilter", "");
    await this.$store
      .dispatch("fetchAppsForDelegateAtLocation", {
        locationId: this.locationId,
        locType: this.locType,
        staffId: this.staffId
      })
      .finally(() => (this.loaded = true));

    this.loaded = true;
    window.scroll(0, 0);
  },
  beforeDestroy() {
    this.$store.commit("setDelegatableAppsFilter", "");
  },
  mixins: [searchDebounce],
  methods: {
    listAvailableTasks(tasks) {
      return tasks && tasks.length
        ? tasks.filter(t => t.provisioned).map(t => t.name)
        : [];
    },
    baseParams() {
      return {
        locationId: this.locationId,
        locType: this.locType,
        locationName: this.locationName,
        detUserId: this.staff.detuserid,
        staffId: this.staff.netRegId,
        staffRole: this.staff.staffRole
      };
    },
    toggleAppDelegation(e, app) {
      e.stopPropagation();
      if (
        !this.fnActionByName(UI_ACTIONS.DELEGATE_APP) &&
        !this.fnActionByName(UI_ACTIONS.UNDELEGATE_APP)
      )
        return;
      if (app.taskId != null) {
        const wasProvisioned = this.delegatedAppTaskIds.some(
          a => a === app.taskId
        );
        const provisionAllFlag =
          !wasProvisioned &&
          this.delegatedAppTaskIds.length ===
            this.unfilteredApplications.length - 1;

        const revokeAllFlag =
          wasProvisioned && this.delegatedAppTaskIds.length === 1;

        const params = Object.assign(this.baseParams(), {
          applicationId: app.id,
          applicationTaskId: app.taskId,
          applicationCode: app.code,
          enabledTasks: this.delegatedAppTaskIds.filter(
            id => id !== app.taskId
          ),
          provision: !wasProvisioned,
          provisionAll: provisionAllFlag,
          revokeAll: revokeAllFlag
        });
        //console.log("params before calling delegateApp: " + JSON.stringify(params));

        if (this.staff.netRegId) {
          this.netRegIdNotPresent = false;
          this.$store.dispatch("delegateApp", params);
        } else {
          this.netRegIdNotPresent = true;
          this.$refs.netRegIdNotPresent.open();
        }
      }
    },
    toggleAll(e) {
      e.stopPropagation();
      if (
        this.hasSearchString ||
        (!this.fnActionByName(UI_ACTIONS.DELEGATE_APP) &&
          !this.fnActionByName(UI_ACTIONS.UNDELEGATE_APP))
      )
        return;
      if (this.staff.netRegId) {
        this.netRegIdNotPresent = false;
        const provisionAll = !this.isAllDelegated;
        const params = Object.assign(this.baseParams(), {
          applicationId: "1",
          applicationTaskId: "",
          enabledTasks: this.delegatedAppTaskIds,
          provision: provisionAll,
          provisionAll: provisionAll,
          revokeAll: !provisionAll
        });
        this.$store.dispatch("delegateAllApps", params);
      } else {
        this.netRegIdNotPresent = true;
        this.$refs.netRegIdNotPresent.open();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item__avatar {
  min-width: 54px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 90px;
}

.listTitle {
  line-height: 1.5rem;
}

::v-deep .v-list--two-line .v-list-item {
  min-height: 72px;
  height: auto;
  overflow: visible;
  white-space: normal;
}

.theme--dark {
  .data-table--header {
    background-color: #263238;
  }
}

.data-table--header {
  font-weight: 600;
  background-color: #cfd8dc;

  .col .d-flex {
    padding: 5px 0;
  }
}

::v-deep {
  .msa-list {
    .v-list {
      padding: 0;
    }

    .v-list-item {
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }

    .theme--dark {
      .v-list-item {
        border-bottom: 1px solid #6e6e6e;
      }
    }
  }
}
</style>
